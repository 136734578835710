.debug-panel{
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    top: 65%;
    left: 10%;
    color: white;
    height: 300px;
    width: 600px;
    z-index: 1;
    background-color: rgb(193, 173, 144);
    box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
    border-bottom: 1px solid #E7E5E4;
    border-radius: 25px;
}
#debug-heading{
    position: relative;
    top: -20px;
    left: 35%;
    margin: 0;
    padding: 0 10px;
    font-family: Outfit;
    font-weight: normal;
    background-color: rgb(193, 173, 144);
    box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
    border-bottom: 1px solid #E7E5E4;
    width: 150px;
    border-radius: 25px;
}
#debug-close{
    position: relative;
    top: -35px;
    left: 570px;
    cursor: pointer;
}
#devices{
    position: relative;
    top: -30px;
    left: 40px;
    font-size: small;
    overflow-y: scroll;
    width: 89%;
    height: 65%;
}
#devices::-webkit-scrollbar {
    width: 5px; 
  }
  
#devices::-webkit-scrollbar-track {
    background-color: transparent; 
}
  
#devices::-webkit-scrollbar-thumb {
    background-color: rgb(102, 87, 67);
    border-radius: 5px; 
}
  
#devices::-webkit-scrollbar-thumb:hover {
    background-color: rgb(102, 87, 67); 
}

#devices tr > td:nth-child(1) {
    width: 160px;
}

#devices tr > td:nth-child(2) {
    padding-left: 50px;
    width: 370px;
}
.debug-btn{
    padding: 0;
    border: none;
    background-color: transparent;
    margin: 0;
    color: white;
    cursor: pointer;
}
#debug-send-btn{
    position:relative;
    top: -200px;
    left: 450px;
    width: 110px;
    height: 50px;
    border: none;
    cursor: pointer;
    background-color: transparent; 
}
