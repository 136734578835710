@font-face {
  font-family: 'BackToSchool'; 
  src: url('/public/fonts/Back\ to\ School.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}
body{
  background-color: white;
  overflow: hidden;
  height: 100%;
}
video {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit:fill;
  cursor: pointer;
}
.paw-container {
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
  border: 2px solid rgb(193, 173, 144);
  border-bottom: 1px solid #E7E5E4;
  padding: 0px 10px;
  max-height: 5vh;
  border-radius: 35px;
  margin: 0;
}

.paw-container h3{
  font-size: 2vh;
}

.paw-container-backpack {
  position: relative;
  top: -100px;
  left: 25%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
  border: 2px solid rgb(193, 173, 144);
  border-bottom: 1px solid #E7E5E4;
  padding: 0px 10px;
  max-height: 5vh;
  border-radius: 35px;
  margin: 0;
  width: 50%;
}

.paw-container-online {
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67)1px 3px 1px 3px;
  border-bottom: 1px solid #E7E5E4;
  padding: 15px 5px 6px 5px;
  border-radius: 35px;
  border: 2px solid rgb(193, 173, 144);
  transform: translateY(-5px);
}

.paw-container-loader {
  width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67)1px 3px 1px 3px;
  border-bottom: 1px solid #E7E5E4;
  padding: 15px 5px 6px 5px;
  border-radius: 35px;
  border: 2px solid rgb(102, 87, 67);
}

h3 {
  font-family: 'BackToSchool';
  letter-spacing: 2px; 
  color: white;
  text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px  3px 0 black,
    3px  3px 0 black;
  font-size: 2rem;
}

.header{
  position: absolute;
  width: 100vw;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.App {
  text-align: center;
  background-image: url("/public/images/backgroundEmpty.png");
  background-size: cover; 
  background-repeat: no-repeat; 
  height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.unityBtn{
  padding: 16px;
  border-radius: 50%;
  margin: 10px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom:1%;
  right: 2%;
  z-index: 250000;
  background-color:rgb(193, 173, 144);
}

.loading-text{
  font-size: 250%;
  color: white;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  letter-spacing: 2px;
  font-weight: bold;
  padding-right: 20px;
}
.avatar-input-area1{
  margin: 10px auto 10px auto;
  text-align: center; 
  background-color: rgb(193, 173, 144);
  border: 3px solid rgb(102, 87, 67);
  border-radius: 40px;
  padding: 2.5%;
  width: 80%;
  max-width: 500px;
  height: 20vh;
  min-height: 20px;
  color: black;
}
.avatar-input-area2 {
  margin: 0px auto 50px auto;
  text-align: center; 
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(193, 173, 144);
  border: 3px solid rgb(102, 87, 67);
  border-radius: 40px;
  padding: 1%;
  width: 80%;
  max-width: 500px;
  min-height: 50px;
  color: black;
}
.avatar-selection {
  width: 100%;
  font-family: 'BackToSchool';
  padding: 5%;
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  background-color: rgb(235, 235, 235);
  border-radius: 50px;
  transform: translateY(-20px);
  box-sizing: border-box;
}

.avatar-selection img {
  width: 50%; 
  max-width: 300px; 
  min-width: 100px; 
  height: auto;
  border-radius: 20px;
}

.avatar-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px; 
}


.unity{
  width: 100vw;
  height: 100vh;
}
/* CSS */
.button-19 {
  font-size: 2vh;
  font-weight: 500;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  line-height: 1;
  border-radius: 30px;
  border-width: 2px solid transparent;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  background-color: #2f90db;
  box-shadow: 0px 4px 0px 0px #2673AF;
  border: 1px solid #2f90db;
  border-bottom: 2px solid #ACD3F1;
}
.button-19:active{
  box-shadow: 0px 2px 0px 0px #2673AF;
  transform: translateY(2px)
}
.button-19:disabled {
  background-color: #A9A29D;
  box-shadow: 0px 4px 0px 0px #79716B;
  border: 1px solid #A9A29D;
  border-bottom: 2px solid #E7E5E4;
  cursor: not-allowed;
  color: #fff;
}

.button-19:disabled:hover {
  background-color: #98a3ac5e;
  cursor: not-allowed;
  color: #fff;
}

.avatar-btn{
  font-size: 2vh;
  font-weight: 500;
  line-height: 1;
  border-radius: 20px;
  border-width: 2px solid transparent;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  margin: auto;
}
.button-container {
  position: absolute;
  top: 50%;
  left: 48%;
  z-index: 1;
}

.button-container button {
  background-color: transparent;
  padding: 15px 30px;
  border: none;
  color: white;
  text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px  3px 0 black,
    3px  3px 0 black;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.male {
  padding: 10px;
  font-family: 'BackToSchool';
  text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px 3px 0 black,
    3px 3px 0 black;
  letter-spacing: 2px;
  background-color: #2F90DB;
  box-shadow: 0px 4px 0px 0px #2673AF;
  transition: all 0.1s ease-in-out; 
  border: 1px solid #2F90DB;
  border-bottom: 2px solid #ACD3F1;
}

.male:active {
  box-shadow: 0px 2px 0px 0px #2673AF;
  transform: translateY(2px);
}

.male:hover, .button-19:hover{
  background-color: #a0c9e9;
}
.female{
  padding: 10px;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  letter-spacing: 2px;
  box-shadow: 0px 4px 0px 0px #656F34;
  background-color: #A9B957;
  transition: all 0.1s ease-in-out; 
  border: 1px solid #A9B957;
  border-bottom: 2px solid #DDE3BC;
}
.female:active{
  box-shadow: 0px 2px 0px 0px #656F34;
  transform: translateY(2px);
}
.female:hover {
  background-color:  #d5e09c;
}
.unity{
  width: 100vw;
  height: 100vh;
  background-color: white;
  cursor: default !important;
  z-index: 0;
}
.custom-input{
  min-width: 300px;
  padding: 0;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, .8);
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  font-family: 'BackToSchool';
  letter-spacing: 2px;
  transform: translateY(-30px);
}

.loadingPage{
  text-align: center;
  background-image: url("/public/images/backgroundHero.png");
  background-size: cover; 
  background-repeat: no-repeat; 
  height: 100vh;
  overflow: hidden;
}
.dots {
  display: inline-block;
  width: 30px; 
  overflow: hidden; 
}
.dots::after {
  content: '';
  animation: dots 1.5s steps(4, end) infinite;
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
.footer-container {
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); ;
  color: black;
  padding: 20px 40px;
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo img {
  margin-right: 10px;
}

.footer-copyright {
  text-align: center;
  flex-grow: 1;
  font-size: 14px;
}

.footer-socials {
  display: flex;
  justify-content: flex-end;
  align-items: center; 
}

.footer-socials a {
  margin: 0 3px; 
}

.footer-socials img {
  width: 28px; 
  height: auto; 
  margin: 0; 
  padding: 0;
}

.profile-inner-image-container{
  position: absolute;
  top: -2px;
  left: -2px; 
  width: calc(99% );
  height: calc(99% );
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
  z-index: 1;
}

.profile-image{
    display: block;
    width: 100%; 
    height: auto; 
    object-fit: cover; 
    object-position: center top; 
    transform: scale(2) translateY(20%); 
}
.profile-modal::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

.profile-modal{
  border-radius: 25px;
  height: 70%;
  min-width: 575px;
  min-height: 775px;
  z-index: 1;
  background-color: rgb(193, 173, 144);
  border: 3px solid rgb(102, 87, 67);
}

.close-button-rpm{
  padding: 0 5px;
  font-weight: bolder;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.custom-input-profile {
  position: relative;
  padding: 0.3rem 1rem;
  border: double 2px rgb(102, 87, 67);
  border-radius: 50px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #f0f0f0;
  color: black;
  font-family: 'BackToSchool';
}

.custom-input-profile::placeholder {
  color: rgb(66, 105, 233);
}
ul{
  list-style-type: none;
}

.rotating-paw{
  animation: spin 2s linear infinite;
}

.loader {
  position: relative;
  width: 100%;
  height: 15px;
  background-color: #f0f0f0; 
  border: 2px solid #79716B; 
  border-radius: 10px;
  overflow: hidden; 
}

.loader-progress {
  height: 100%; 
  background-color: #656F34;
  background-image: linear-gradient(
    45deg,
    #A9B957 25%,
    transparent 25%,
    transparent 50%,
    #A9B957 50%,
    #A9B957 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.2em 1.2em;
  border-radius: 8px;
  transition: width 0.3s ease-in-out; 
  width: 0; 
}

@keyframes barStripe {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  z-index: 3;
}

.green {
  background-color: #adff5f !important;
}
.red {
  background-color: #ff689d !important;
}


  /* Ensure the iframe takes full viewport */
  iframe {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    border: none;
  }