.RPMLogin{
    background-color: #A9A29D;
    font-size: 2vh;
    font-weight: 500;
    font-family: 'BackToSchool';
    text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px  3px 0 black,
    3px  3px 0 black;
    color: #fff;
    border-radius: 20px;
}

.join-button {
  position: relative;
  z-index: 500000;
  font-size: 2vh;
  font-weight: 500;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  line-height: 1;
  border-radius: 30px;
  border-width: 2px solid transparent;
  padding: 10px 15px;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  background-color: #2f90db;
  box-shadow: 0px 4px 0px 0px #2673AF;
  border: 1px solid #2f90db;
  border-bottom: 2px solid #ACD3F1;
}
.join-button:active{
  box-shadow: 0px 2px 0px 0px #2673AF;
  transform: translateY(2px)
}
.join-button:disabled {
  background-color: #A9A29D;
  box-shadow: 0px 4px 0px 0px #79716B;
  border: 1px solid #A9A29D;
  border-bottom: 2px solid #E7E5E4;
  cursor: not-allowed;
  color: #fff;
}

.join-button:disabled:hover {
  background-color: #98a3ac5e;
  cursor: not-allowed;
  color: #fff;
}
.rpm-button:hover{
  background-color: #a0c9e9;
}


.asset-button{
  font-size: 2vh;
  font-weight: 500;
  line-height: 1;
  border-radius: 20px;
  border-width: 2px solid transparent;
  padding: 5px 15px;
  margin: 5px;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  padding: 10px;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  letter-spacing: 2px;
  box-shadow: 0px 4px 0px 0px #656F34;
  background-color: #A9B957;
  transition: all 0.1s ease-in-out; 
  border: 1px solid #A9B957;
  border-bottom: 2px solid #DDE3BC;
}

.asset-button:active{
  box-shadow: 0px 2px 0px 0px #656F34;
  transform: translateY(2px);
}
.asset-button:hover {
  background-color:  #d5e09c;
}
.rpm-wrapper{
  border-radius: 25px;
  height: 60%;
  background-color: rgb(193, 173, 144);
  border: 3px solid rgb(102, 87, 67);
}

.rpm-container {
  height: 90%;
  width: 98%;
  display: flex;
  flex-direction: row;
  margin: 10px auto auto auto;
  z-index: 7;
}

.rpm-container-modal{
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 95%;
  top: -20px;
  width: 95%;
  margin: 0;
  z-index: 70;
  overflow: auto;
}
.title-container{
  position: relative;
  top: -100px;
  left: 25%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
  border: 2px solid rgb(193, 173, 144);
  border-bottom: 1px solid #E7E5E4;
  padding: 0px 10px;
  max-height: 5vh;
  border-radius: 35px;
  margin: 0;
  width: 50%;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white;
  background: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 50;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: large;
}

.button-85:before,
.next::before {
  content: "";
  background: linear-gradient(
    45deg,
    var(--primary-color),
    var(--secondary-color),
    var(--accent-color),
    var(--highlight-color),
    var(--error-color)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@media (max-width: 1300px) {
  .rpm-container {
    flex-direction: column;
    height: fit-content;
  }
  .asset-picker{
    margin-top: 10%;
  }
  .rpm-wrapper{
    height: 90%;
  }
  .rpm-container-modal{
    flex-direction: column;
    height: fit-content;
  }
  .asset-picker{
    height: 320px !important;
  }
  .profile-modal{
    height: 90%;
    width: 100%;
    background: none;
  }
  .visage{
    height: 300px !important;
    width: 100% !important;
    right: 20px !important;
  }
}

.loader-rpm {
    height: 5px;
    width: 5px;
    color: rgb(42, 222, 138);
    box-shadow: -10px -10px 0 5px,
                -10px -10px 0 5px,
                -10px -10px 0 5px,
                -10px -10px 0 5px;
    animation: loader-38 6s infinite;
  }

  @keyframes loader-38 {
    0% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px;
    }
    8.33% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px -10px 0 5px;
    }
    16.66% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px 10px 0 5px;
    }
    24.99% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    33.32% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px -10px 0 5px;
    }
    41.65% {
      box-shadow: 10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px -10px 0 5px;
    }
    49.98% {
      box-shadow: 10px 10px 0 5px,
                10px 10px 0 5px,
                10px 10px 0 5px,
                10px 10px 0 5px;
    }
    58.31% {
      box-shadow: -10px 10px 0 5px,
                  -10px 10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    66.64% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    74.97% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    83.3% {
      box-shadow: -10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    91.63% {
      box-shadow: -10px -10px 0 5px,
                  -10px 10px 0 5px,
                  -10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    100% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px;
    }
  }


        
      
