.chat{
  position: fixed;
  right: 50px;
  bottom: 1%;
  border-radius: 20px;
  font-family: 'BackToSchool';
  letter-spacing: 2px;
  z-index: 1;
}
.chat-header-container {
  margin-left: -40px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 10px; 
  z-index: 2;
}

.chat-pill {
  padding: 6px 16px;
  background: linear-gradient(349deg, rgba(104,40,162,1) 30%, rgba(119,205,221,1) 85%);
  color: white;
  border-radius: 25px; 
  z-index: 1;
}

.studio-title {
  color: black; 
  font-weight: lighter;
  font-size: 30px;
  margin-left: 50px;
  z-index: 1;
}
.paw-container-chat{
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
  border-bottom: 1px solid #E7E5E4;
  padding: 0px 10px;
  border-radius: 15px;
  border: 2px solid rgb(193, 173, 144);
  z-index: 2;
  margin-bottom: 65px;
  margin-left: 150px;
}

.paw-container-chat-name{
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 20px; 
  background-color: rgb(193, 173, 144);
  box-shadow: rgb(102, 87, 67) 1px 3px 1px 3px;
  border-bottom: 1px solid #E7E5E4;
  border-radius: 15px;
  border: 2px solid rgb(193, 173, 144);
  z-index: 2;
  display: inline-block; 
  width: auto;
  padding: 0px 5px;
  transform: translateY(15px);
}

.chat-body {
  width: 100%;
  display: block;
  position: relative;
  padding: 1.3rem;
  max-width: 800px;
  border-radius: 5%;
}

.chat-bubble-image,
.user-online-image,
.profile{
 background-image: url("/public/images/sky.png");
 background-size: cover;
}

.image-container {
  position: relative;
  right: 15px;
  width: 65px; 
  height: 65px; 
  border-radius: 50%;
  z-index: 1;
}

.image-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.5px; 
  transform: rotate(180deg); 
  pointer-events: none; 
}

.image-inner-container {
  position: relative;
  top: 6px; 
  left: 6px; 
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 15%;
  overflow: hidden;
}
.image-inner-container-user {
  position: relative;
  top: 6px; 
  left: 310px; 
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 15%;
  overflow: hidden;
}

.chat-bubble-image {
  width: 100%; 
  height: auto; 
  object-fit: cover;
  object-position: center top;
  transform: scale(2.2) translateY(20%); 
}

.outer-image-container{
  margin-bottom: 0;
  margin-top: 0;
}

.image-container-online{
  position: relative;
  display: inline-block;
  width: 18px; 
  height: 18px; 
  margin: 2px;
  border-radius: 15%;
  padding: 11px;
  color: 'white';
  display: 'flex'; 
  align-items: 'center'; 
  justify-content: 'center';
  background: 'linear-gradient(36deg, rgba(44,128,0,1) 30%, rgba(40,179,238,1) 68%)';
}
.users-online{
  position: absolute;
  top: 100px;
  left: 50px;
  z-index: 1;
}

.users-online-inner-image-container{
  position: absolute;
  top: 3px;
  left: 2px; 
  width: calc(100% -6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px rgb(102, 87, 67) solid;
}
.user-online-image{
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: center top; 
  transform: scale(2.2) translateY(20%); 
}

.soledad{
  transform: scale(1.9) translateY(12%);
}

.chat-body {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.chat-body::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5%;
  z-index: -1; 
}

.chat-body::after {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent; 
  z-index: 0;
  pointer-events: none;
  border-radius: 5%;
}

.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}

.chat-container{
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  height: 30vh;
  margin-bottom: 25px;
}

.chat-bubble-container{
  position: relative;
  right: 20px;
}

.chat-bubble{
  position: relative;
  left: 80px;
  top: -75px;
  text-decoration: none;
  text-align: left;
  width: 80%;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
  z-index: 1;
}

.bubble-timestamp{
  margin-right: auto;
  color: white;
}

.user-chat-bubble{
  position: relative;
  right: 0px;
  top: -75px;
  text-decoration: none;
  text-align: left;
  width: 80%;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
  z-index: 1;
}
.user-bubble-timestamp{
  color: white;
  position: relative;
}

.chat-input{
  display: flex;
  align-items: center;
  margin: 1% auto;
  margin-top: 4%;
  width: 80%;
  z-index: 25000 !important;
  padding:0.2rem 0.2rem 0.2rem 1.2rem;
  border: double 1px transparent;
  border-radius: 50px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgb(94, 83, 85,0.8);
  position: relative;
  z-index: 2;
}

#chat-input{
  border: none;
  background-color: transparent;
  width: 90%;
  height: auto;
  color: black;
  font-size: large;
  font-family: 'BackToSchool';
  letter-spacing: 2px;
  line-height: 1.5; 
  padding: 0.2rem; 
}

#chat-input:focus {
  outline: none; 
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: rgb(199, 180, 158, 0.8);
  cursor: pointer;
}

.emoji-picker{
  position: absolute;
  bottom: 130%;
  right: 10%;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.send-button{
  background-color: rgb(199, 180, 158, 0.8);
  border: none;
  color: white;
  border-radius: 50%;
  padding: 3%;
  cursor: pointer;
}

.is-typing-container{
  position: absolute;
  font-size: 15px;
  color: black;
  bottom: 88px;
  left: 50px;
  margin-left: 52px;
  margin-top: 20px;
  z-index: 1;
}

.dot-text-chat{
  position: absolute;
  left: 48px;
  top: 38px;
  font-size: 200%;
  margin: 0;
  padding: 0;;
}
.red-text-chat{
  color: red;
}
.green-text-chat{
  color: lime;
}

/* Wobble Animation */
@keyframes wobble {
  0%, 100% {
    transform: translateX(0);
  }
  15%, 45%, 75% {
    transform: translateX(-1px);
  }
  30%, 60%, 90% {
    transform: translateX(1px);
  }
}

.wobble {
  animation: wobble 0.5s ease-in-out;
}

.scroll-to-bottom {
  position: fixed; 
  bottom: 90px;
  right: 40%;
  background-color: transparent;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: black;
  border: 2px solid transparent;
  cursor: pointer;
  z-index: 1000;
}


.scroll-to-bottom:hover {
  background-color: rgba(3, 5, 29, 0.25);
  color: black;
}
.name{
  color: white;
  text-shadow: 
    0px 0px 0 black,  
    1px 0px 0 black,
    0px  1px 0 black,
    1px  1px 0 black;
}